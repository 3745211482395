var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"elevation-0"},[_c('v-card-title',{staticClass:"pa-4"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4","lg":"4","xl":"4"}},[_c('v-autocomplete',{attrs:{"label":"Select Vehicles","chips":"","multiple":"","items":_vm.vehicles},model:{value:(_vm.selectedVehicles),callback:function ($$v) {_vm.selectedVehicles=$$v},expression:"selectedVehicles"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4","lg":"4","xl":"4","offset-md":"2","offset-lg":"2","offset-xl":"2"}},[_c('v-select',{attrs:{"items":_vm.reportItems,"label":"Select Report"},model:{value:(_vm.reportType),callback:function ($$v) {_vm.reportType=$$v},expression:"reportType"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2","lg":"2","xl":"2"}},[_c('div',{staticClass:"float-right"},[(this.selectedVehicles.length <= 5)?_c('fab-button',{attrs:{"dark":"","top":"","xsmall":"","icon":'mdi-play',"tooltipSpan":'Generate Report'},on:{"click":function($event){return _vm.getData()}}}):_vm._e(),_vm._v(" "),_c('fab-button',{attrs:{"dark":"","top":"","xsmall":"","icon":'mdi-file-excel',"tooltipSpan":'Download Report'},on:{"click":function($event){return _vm.downloadExcel()}}}),_vm._v(" "),_c('fab-button',{attrs:{"dark":"","top":"","xsmall":"","icon":'mdi-refresh',"tooltipSpan":'Refresh'},on:{"click":function($event){return _vm.getData()}}})],1)])],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-3",attrs:{"cols":"12","sm":"12","md":"2","lg":"2","xl":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date & Time From","readonly":""},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDateFrom),callback:function ($$v) {_vm.menuDateFrom=$$v},expression:"menuDateFrom"}},[_c('v-date-picker',{attrs:{"max":_vm.$dateFormat(new Date(), 'YYYY-MM-DD')},on:{"input":function($event){_vm.menuDateFrom = false}},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}})],1)],1),_c('v-col',{staticClass:"px-3",attrs:{"cols":"12","sm":"12","md":"3","lg":"3","xl":"3"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.timeFrom),expression:"timeFrom"}],staticStyle:{"width":"250px"},attrs:{"type":"time","step":"1"},domProps:{"value":(_vm.timeFrom)},on:{"input":function($event){if($event.target.composing){ return; }_vm.timeFrom=$event.target.value}}})]),_c('v-col',{staticClass:"pr-2",attrs:{"cols":"12","sm":"12","md":"2","lg":"2","xl":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date & Time To","readonly":""},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDateTo),callback:function ($$v) {_vm.menuDateTo=$$v},expression:"menuDateTo"}},[_c('v-date-picker',{attrs:{"max":_vm.dateToLimit,"min":_vm.dateFrom},on:{"input":function($event){_vm.menuDateTo = false}},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}})],1)],1),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"12","sm":"12","md":"3","lg":"3","xl":"3"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.timeTo),expression:"timeTo"}],staticStyle:{"width":"250px"},attrs:{"type":"time","step":"1"},domProps:{"value":(_vm.timeTo)},on:{"input":function($event){if($event.target.composing){ return; }_vm.timeTo=$event.target.value}}})])],1)],1)],1)],1),_c('v-divider'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.items,"disable-pagination":"","hide-default-footer":"","server-items-length":_vm.items.length},scopedSlots:_vm._u([(_vm.items.length === 0)?{key:"no-data",fn:function(){return [_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"md":"6","offset-md":"3"}},[_c('span',{staticClass:"title font-weight-bold"},[_vm._v("NO DATA AVAILABLE.")])])],1)],1)]},proxy:true}:{key:"body",fn:function(ref){
var items = ref.items;
return _vm._l((items),function(item,index){return _c('tbody',{key:index},_vm._l((item.alerts),function(obj,i){return _c('tr',{key:i},[_c('td',{staticClass:"text-no-wrap text-center",style:(i === 0 ? '' : 'display: none;')},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('td',{staticClass:"text-no-wrap text-uppercase",style:(i === 0 ? '' : 'display: none;')},[_vm._v(" "+_vm._s(item.plate_no)+" ")]),_c('td',{style:(i === 1 ? 'border-bottom: none;' : 'display: none;'),attrs:{"colspan":"2","rowspan":item.alerts.length}}),_c('td',{staticClass:"text-center text-no-wrap"},[_vm._v(" "+_vm._s(_vm.$dateFormat(obj.datetimestamp, "YYYY-MM-SS"))+" ")]),_c('td',{staticClass:"text-center text-no-wrap"},[_vm._v(" "+_vm._s(_vm.$dateFormat(obj.datetimestamp, "HH:mm:ss"))+" ")]),_c('td',{staticClass:"text-no-wrap viewCurrentLocation",on:{"click":function($event){return _vm.viewCurrentLocation(item.plate_no, obj)}}},[_vm._v(" "+_vm._s(obj.location ? obj.location : "--")+" ")])])}),0)})}}],null,true)})],1)],1),_c('mini-dialog',{attrs:{"dialog":_vm.viewCurrentLocationDialog,"max-width":'1000px',"cardActions":false}},[_c('v-card-title',{staticClass:"pl-3 gradientColor"},[_c('span',{staticClass:"headline font-weight-black white--text"},[_vm._v("View Current Location")]),_c('v-spacer'),_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":_vm.closeViewCurrentLocationDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pa-0",staticStyle:{"height":"500px"}},[_c('official-map',{ref:"map"})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }